import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, Seo} from '../components';
import {
    AboutFive,
    AboutFour,
    AboutThree, BrandTwo,
    ContactTwo,
    FeatureTwo,
    FooterOne,
    HeaderOneCopy,
    ProcessTwo, ServiceThree, TestimonialTwo
} from '../containers';
import productsData from '../data/products.json';
import BizVaultData from "../data/BizVault.json";
import AboutEight from "../containers/Abouts/AboutEight";
import AboutEightWithSideTabs from "../containers/Abouts/AboutEightWithSideTabs";
import ServiceThreeDynamic from "../containers/Services/ServiceThreeDynamic";



const aboutFiveData = {
    title: "Optimize efficiency and accomplish tasks swiftly.",
    subtitle: "Unify and Simplify Tasks",
    points: [
        {
            id: "1",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/process-2-1.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/sr-bg-shape-2-1.png",
            title: "Document Management",
            text: "Efficient document management for business processes. Integration with ERP systems.",
            subPoints: ["Customers", "Suppliers", "Employees"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/fe-2-2.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/sr-bg-shape-2-1.png",
            title: "Hierarchical structure",
            text: "Hierarchical User Roles for precise control. Layered structure ensures secure project management.",
            subPoints: ["Admins", "Users", "Team Members", "Business Partners"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/sr-icon-3-5.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/sr-bg-shape-2-1.png",
            title: "Improve collaboration",
            text: "Enhance team collaboration, centralize project communications for better efficiency.",
            subPoints: ["Comments", "Docs", "Whiteboards", "Clips"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/sr-icon-3-3.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/fe-1-2.png",
            title: "Enhance visibility",
            text: "Gain clear visibility into tasks and responsibilities, ensuring accountability.",
            subPoints: ["Dashboards", "Reports"]
        },
        {
            id: "1",
            path: "/services",
            image: "images/service/sr-3-1.jpg",
            icon: "images/icon/process-1-2.png",
            bgShape: "images/bg/sr-box-bg-1.jpg",
            bgShape2: "images/shape/process-1-2.png",
            title: "Automate work",
            text: "Streamline business processes with powerful automations, improve operational efficiency.",
            subPoints: ["Templates", "Reports"]
        },
    ]
};
const BizVault = () => (
    <Fragment>
        <Seo title="BizVault"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="BizVault"
                    pageSubTitle={"Designed to manage any type of document."}
                    pageDesc={"Boost productivity by simplifying tasks in a unified space." }
                    bgImage="images/bg/bg-image-2.png"/>

        <ServiceThreeDynamic
            className="space-top space-extra-bottom"
            data={aboutFiveData}
        />



        {/*<AboutEightWithSideTabs*/}
        {/*    data={aboutEightData}*/}
        {/*    title={"Optimal for account management needs"}*/}
        {/*    subtitle={"Quickly initiate with pre-configured solutions."}*/}
        {/*    className="space-top space-extra-bottom"/>*/}

        <TestimonialTwo
            bigImage={true}
            title={"One app to replace them all"}
            subtitle={"Get everyone working in a single platform"}
            data={BizVaultData} className="space-top space-extra-bottom"/>

        <FooterOne/>

        <ScrollTopBtn/>
    </Fragment>
);

export default BizVault;