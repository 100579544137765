import React from 'react';
import {Button, InfoMedia, List, SecSubTitle, SecTitle} from '../../components';

const AboutSevenImages = ({title, description, imgPath, flip = false, index}) => (
    <div className="background-image" style={{backgroundImage: !flip ? 'url(images/bg/about-bg-5-1.jpg)' : ''}}>
        <div className="container container-style1">
            <div className="row flex-row-reverse align-items-center gx-70 AboutSevenCopyMedia-min-lg">
                {index % 2 === 1 ? (
                    <div className={'flex-row-reverse d-flex flex-wrap'}>
                        <div className="col-12 col-lg-6 ">
                            <div style={{height: '80%',display:"flex",flexDirection:'column'}} className="about-box2 justify-content-center ">
                                <SecTitle className="text-capitalize">{title}</SecTitle>
                                <p>{description}</p>
                            </div>
                        </div>
                        <div className="col-12 col-lg-6 px-4">
                            <img style={{height: '80%', objectFit: 'cover'}} className={'about-box2'} src={imgPath}
                                 alt="aboutthumb"/>
                        </div>
                    </div>
                ) : (<div className={'flex-row-reverse d-flex flex-wrap'}>

                    <div className="col-12 col-lg-6 px-4">
                        <img style={{height: '80%', objectFit: 'cover'}} className={'about-box2'} src={imgPath}
                             alt="aboutthumb"/>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div style={{height: '80%',display:"flex",flexDirection:'column'}} className="about-box2 justify-content-center ">
                            <SecTitle className="text-capitalize h1">{title}</SecTitle>
                            <p>{description}</p>
                        </div>
                    </div>
                </div>)}
            </div>


            {/*<div className="row flex-row-reverse align-items-center gx-70 ">*/}
                <div className={'AboutSevenCopyMedia-max-lg'}>
                    <div className="col-12">
                        <img style={{height: '100%', objectFit: 'cover'}} className={'about-box2'} src={imgPath}
                             alt="aboutthumb"/>
                    </div>
                    <div className="col-12">
                        <div className="">
                            <SecTitle className="text-capitalize">{title}</SecTitle>
                            <p>{description}</p>
                        </div>
                    </div>

                </div>
            {/*</div>*/}

        </div>
    </div>
);

export default AboutSevenImages;