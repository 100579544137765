import React, {useEffect, useState} from 'react';
import {ImageBoxFour, InfoMedia, SecSubTitle, SecTitle} from '../../components';

const AboutFourTimed = ({data, className, reverse = true}) => {
    const [idx, setIdx] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIdx(prevCount => (prevCount + 1) % 6); // Cycle from 0 to 5
        }, 3000);

        return () => clearInterval(interval); // Cleanup the interval on unmount
    }, []);

    return (
        <div className={className}>
            <div className="container">
                <div className={`row align-items-center`}>
                    <div className="col-lg-4 mb-30 ">
                        <div className="img-1 d-flex justify-content-center w-100p" style={{margin:0}}><img style={{width:'90%',objectFit:'cover'}} src={data?.points[idx]?.img} alt="about"/></div>
                    </div>
                    <div className="col-lg-8 mb-30 pt-10 pt-lg-0 text-center text-md-start">
                        <SecSubTitle ><i className="fas fa-bring-forward"/>{data.subtitle}</SecSubTitle>
                        <SecTitle className="h1 mb-3 pb-3 normal-title text-wrap">{data.title}</SecTitle>
                        {
                            data?.points.map((item, index) => {
                                return (
                                    <InfoMedia
                                        light={index === idx}
                                        className="media-order"
                                        number={"0" + (index + 1)}
                                        title={item.title}
                                        info={item.text}
                                        styleIcon={{
                                            backgroundColor:index !== idx ? '#1B3C69' : 'transparent', height: 60,
                                            color: index !== idx ? 'white':'#1B3C69', fontSize: 22,
                                            display: 'flex', justifyContent: 'center', alignItems: 'center',
                                            border:`1px solid #1B3C69`
                                        }} />
                                )
                            })
                        }

                    </div>
                </div>
            </div>
        </div>
    )
};

export default AboutFourTimed;