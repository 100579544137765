import React, {Fragment, useEffect} from "react";
import {Route, Routes, useLocation} from "react-router-dom";

// Home Pages
import HomeOne from '../pages/HomeOne';
import HomeTwo from '../pages/HomeTwo';
import HomeThree from '../pages/HomeThree';

// About Page
import About from '../pages/About';

// Blog Pages
import Blog from '../pages/Blog';

// Pricing Plans Page
import PricePlan from '../pages/PricePlan';

// Service Pages
import ServiceDetails from '../pages/ServiceDetails';

// Team Pages
import Team from '../pages/Team';
import CareerDetails from '../pages/CareerDetails';

// Project Pages
import Project from '../pages/Project';
import ProjectDetails from '../pages/ProjectDetails';

// Contact Page
import Contact from '../pages/Contact';
import Privacy from '../pages/Privacy';

// BePartner Page
import BePartner from '../pages/BePartner';
// Sap B1  Pages
import SapB1Implementation from '../pages/SapB1Implementation';
import SapB1Integration from '../pages/SapB1Integration';
import SapB1Addon from '../pages/SapB1Addon';



// solutions Sap Business One
import SapBusinessOne from '../pages/SapBusinessOne';
import SapBusinessOneInterCompany from "../pages/SapBusinessOneInterCompany";

// Not Fouond Page
import NotFound from '../pages/NotFound';
import Products from "../pages/Products";
import Careers from "../pages/Careers";
import BlogDetails from "../pages/BlogDetails";
import Soon from "../pages/Soon";
import BusinessAnalytics from "../pages/BusinessAnalytics";
import MobileApps from "../pages/mobileApps";
import WebApps from "../pages/WebApps";
import CloudHosting from "../pages/CloudHosting";
import CloudMigration from "../pages/CloudMigration";
import EVO from "../pages/EVO";
import BizVault from "../pages/BizVault";
import CustomAddon from "../pages/CustomAddon";
import SolutionArchitecture from "../pages/SolutionArchitecture";
import SolidScan from "../pages/SloidScan";


const AppRouters = () => {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <Fragment>
            <Routes>
                <Route path="/" exact element={<HomeOne/>}/>
                <Route path="/home-two" exact element={<HomeTwo/>}/>
                <Route path="/home-three" exact element={<HomeThree/>}/>
                <Route path="/about" exact element={<About/>}/>
                <Route path="/price-plan" exact element={<PricePlan/>}/>
                <Route path="/services" exact element={<ServiceDetails/>}/>
                <Route path="/products" exact element={<Products/>}/>
                {/*<Route path="/service-details" exact element={<ServiceDetails/>} />*/}
                <Route path="/project" exact element={<Project/>}/>
                <Route path="/project-details" exact element={<ProjectDetails/>}/>
                <Route path="/team" exact element={<Team/>}/>
                {/*<Route path="/team-details" exact element={<CareerDetails/>} />*/}
                <Route path="/blog" exact element={<Blog/>}/>
                <Route path="/Careers" exact element={<Careers/>}/>
                <Route path="/Career-details/:id" exact element={<CareerDetails/>}/>
                {/*<Route path="/blog-grid" exact element={<BlogGrid/>} />*/}
                <Route path="/blog-details/:id" exact element={<BlogDetails/>}/>
                <Route path="/contact" exact element={<Contact/>}/>
                <Route path="/privacy-policy" exact element={<Privacy/>}/>
                <Route path="/be-partner" exact element={<BePartner/>}/>
                <Route path="/sap-business-one" exact element={<SapBusinessOne/>}/>
                <Route path="/sap-business-one-inter-company" exact element={<SapBusinessOneInterCompany/>}/>
                <Route path="/sapb1-implementation" exact element={<SapB1Implementation/>}/>
                <Route path="/sapb1-integration" exact element={<SapB1Integration/>}/>
                <Route path="/sapb1-addon" exact element={<SapB1Addon/>}/>
                <Route path="/business-analytics" exact element={<BusinessAnalytics/>}/>
                <Route path="/mobile-applications" exact element={<MobileApps/>}/>
                <Route path="/cloud-hosting" exact element={<CloudHosting/>}/>
                <Route path="/cloud-migration" exact element={<CloudMigration/>}/>
                <Route path="/web-applications" exact element={<WebApps/>}/>
                <Route path="/EVO-WMS" exact element={<EVO/>}/>
                <Route path="/BizVault" exact element={<BizVault/>}/>
                <Route path="/SolidScan" exact element={<SolidScan/>}/>
                <Route path="/customs-addon" exact element={<CustomAddon/>}/>
                <Route path="/solution-architecture" exact element={<SolutionArchitecture/>}/>
                <Route path="/learningHub" exact element={<Soon title={'Learning Hub'} pageSubTitle={"Empower Yourself Through Continuous Learning"} pageDesc={"Your resource for expanding knowledge and skills. Explore career, hobbies, and more with us."}/>}/>
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </Fragment>
    )
};

export default AppRouters;