import React, {Fragment} from 'react';
import {Breadcrumb, ScrollTopBtn, SecSubTitle, SecTitle, Seo, TitleWrap} from '../components';
import {AboutFour, AboutSeven, AboutThree, ContactTwo, FeatureTwo, FooterOne, HeaderOneCopy} from '../containers';
import SAPB1Video from "../containers/Testimonials/SAPB1Video";
import AboutSix from "../containers/Abouts/AboutSix";
import AboutEight from "../containers/Abouts/AboutEight";
import AboutSevenCopy from "../containers/Abouts/AboutSevenCopy";
import AboutFourCopy from "../containers/Abouts/AboutFourCopy";
import AboutSevenImages from "../containers/Abouts/AboutSevenImages";
import AboutFourTimed from "../containers/Abouts/AboutFourTimed";


const aboutSevenData = [
    {
        flip: false,
        title: 'Scan and Digitize Documents',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Capture high-quality digital copies of your documents using advanced scanning tools, ensuring clarity and accuracy for seamless management.'
    },
    {
        flip: true,
        title: 'Edit, Crop, and Clean Documents Captured Via Camera',
        imgPath: 'images/SolidScan/Edit.png',
        description: 'Easily refine scanned documents with powerful editing tools, including cropping and cleaning, to prepare files for professional use.'
    },
    {
        flip: false,
        title: 'PDF Document Generation',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Convert scanned and edited documents into PDF files with a single tap, creating standardized formats suitable for e-filing.'
    },
    {
        flip: true,
        title: 'Seamlessly Upload Documents to ERP Systems',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Effortlessly connect with ERP systems to attach files directly to transactions, such as invoices or purchase orders, simplifying workflows.'
    },
    {
        flip: false,
        title: ' Backend Portal Handles Browsing, Viewing And Exporting Attachments',
        imgPath: 'images/SolidScan/Scanning.png',
        description: 'Manage your document library with ease using the backend portal, designed for efficient browsing, viewing, and exporting of attachments, streamlining e-filing processes.'
    }
];

const aboutFourData = {
    title: 'DIGITALIZE FILING PROCESS',
    subtitle: 'Ensuring Ease & save efforts',
    points: [
        {
            title: "Browse Transactions & Select Document",
            text: "Easily navigate records and select documents by number, partner, reference, or date.",
            img: 'images/SolidScan/TypesList.png',
        },
        {
            title: "Create New Document",
            text: "Give the document a name to keep it organized and ready for scanning.",
            img: 'images/SolidScan/Create.png',
        },
        {
            title: "Scan Files",
            text: "Capture documents automatically or manually, with options to crop, edit, and save.",
            img: 'images/SolidScan/Scanning.png',
        },
        {
            title: "Generate and Upload",
            text: "Convert documents to PDF and upload them directly to your ERP system.",
            img: 'images/SolidScan/Generate.png',
        },
        {
            title: "Manage Attachments",
            text: "Browsing, view and export attachments.",
            img: 'images/SolidScan/Home.png',
        },
    ]
}


const SolidScan = () => (
    <Fragment>
        <Seo title="SolidScan"/>
        <HeaderOneCopy/>
        <Breadcrumb pageName="SolidScan"
                    pageSubTitle={"E-Filing solution"}
                    pageDesc={"Seamless scanning, editing, and uploading of documents to SAP Business One"}
                    bgImage="images/bg/bg-SolidScan.png"/>


        <div className="container space-extra-bottom">
            <TitleWrap className="text-center space-top ">
                <SecTitle className="text-capitalize h1">SolidScan – Document Scanner<br/>
                    Easiest way to Scan Documents</SecTitle>
                <SecSubTitle> NAVIGATE EFFORTLESSLY THROUGH SOLIDSCAN'S INTUITIVE INTERFACE With Our Friendly User
                    Interface </SecSubTitle>
            </TitleWrap>


            <div className={'w-100p d-flex align-items-center justify-content-center'}>
                <div className={'images-ss-row'}>
                    <img src={'images/SolidScan/Home.png'} alt="tabthumb" style={{
                        objectFit: 'cover',
                        width: '100%',
                        transform: "scale(0.8)",
                        position: 'absolute',
                        marginRight: '100%',
                        marginTop: '15%'
                    }}/>
                    <img src={'images/SolidScan/TypesList.png'} alt="tabthumb"
                         style={{objectFit: 'cover', width: '100%', zIndex: 1}}/>
                    <img src={'images/SolidScan/Scanning.png'} alt="tabthumb" style={{
                        objectFit: 'cover',
                        width: '100%',
                        transform: "scale(0.8)",
                        position: 'absolute',
                        marginLeft: '100%',
                        marginTop: '15%'
                    }}/>

                </div>
            </div>

            <p className="author-degi space-top text-center"> SolidScan is an E-Filing solution, enables seamless
                scanning, editing, and uploading of documents to
                ERP systems. Streamline e-filing and digitize your
                document management by attaching files to
                transactions like invoices and purchase orders, while
                effortlessly managing and sharing them through a
                powerful backend portal</p>


        </div>



        {aboutSevenData?.map((item, index) => <AboutSevenImages
            index={index}
            flip={item.flip}
            title={item.title}
            imgPath={item.imgPath}
            description={item.description}
        />)}

        <AboutFourTimed data={aboutFourData} className="space space-extra-bottom"/>


        <div className={'row align-items-center justify-content-center'}>
            <div className={'col-md-7'}>
                <TitleWrap className="text-center space-top ">
                    <SecTitle className="text-capitalize h1"> Why Choose SolidScan?
                    </SecTitle>
                    <p className="author-style1 text-center">
                        We stand out by offering a solution that is easy to use, ensures data security, and leverages
                        automation to streamline processes, all while being cost-effective. Our platform seamlessly
                        integrates with existing systems, promotes sustainability by reducing waste, enables quick
                        search and retrieval of documents, and is backed by exceptional customer support for a complete,
                        reliable experience.
                    </p>
                </TitleWrap>
            </div>
            <div className={'col-md-5 row align-items-center justify-content-center'}>
                <img src={'images/SolidScan/Secure-1.png'} alt="tabthumb" style={{
                    objectFit: 'cover',
                    width: '60%',

                }}/>
            </div>
        </div>


        <FooterOne/>
        <ScrollTopBtn/>
    </Fragment>
);

export default SolidScan;